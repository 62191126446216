import { AbsenceType } from "./Absence";
import { Currency, FALLBACK_CURRENCY } from "./Currency";
import { Employee } from "./Employee";
import { OperatingUnit } from "./OperatingUnit";
import { OrderStatus } from "./OrderStatus";
import { RentalOrderStatus } from "./RentalOrder";

export const MIN_CUSTOMER_NUMBER = 10001;
export const DEFAULT_ORDER_NUMBERS_START = 700_000;
export const DEFAULT_CREDITOR_NUMBERS_START = 70_000;

export function getDefaultOrderCounters(): AppCompanyCounters["orders"] {
    return {
        [OrderStatus.DRAFT]: 0,
        [OrderStatus.ASSIGNED]: 0,
        [OrderStatus.IN_PROGRESS]: 0,
        [OrderStatus.DONE]: 0,
        [OrderStatus.CHECKED]: 0,
        [OrderStatus.BILLED]: 0,
    };
}

export function getDefaultRentalCounters(): AppCompanyCounters["rentals"] {
    return {
        [OrderStatus.DRAFT]: 0,
        [OrderStatus.IN_PROGRESS]: 0,
        [OrderStatus.DONE]: 0,
        [OrderStatus.CHECKED]: 0,
        [OrderStatus.BILLED]: 0,
    };
}

export enum PaymentMethodStatus {
    INITIAL = "PaymentMethodStatus_INITIAL",
    READY_FOR_SETUP = "PaymentMethodStatus_READY_FOR_SETUP",
    AVAILABLE = "PaymentMethodStatus_AVAILABLE",
}

type AppCompanyCounters = {
    nextOrderNumber: number;
    nextCustomerNumber: number;
    nextCreditorNumber: number;
    orders: Record<OrderStatus, number>;
    rentals: Record<RentalOrderStatus, number>;
};

export class AppCompany {
    public id: string;
    public archived: boolean;
    public name?: string;
    public paymentMethodStatus: PaymentMethodStatus;
    public defaultUnitId: OperatingUnit["id"];
    public defaultOperatingUnitName: OperatingUnit["name"];
    public settings: CompanyWideSettings;
    public counters: AppCompanyCounters;

    constructor(initialValues?: Partial<AppCompany>) {
        this.id = initialValues?.id ?? "";
        this.archived = initialValues?.archived ?? false;
        this.name = initialValues?.name;
        this.paymentMethodStatus = initialValues?.paymentMethodStatus ?? PaymentMethodStatus.INITIAL;
        this.defaultUnitId = initialValues?.defaultUnitId ?? "";
        this.defaultOperatingUnitName = initialValues?.defaultOperatingUnitName ?? "";
        this.settings = {
            ...DEFAULT_APPCOMPANY_SETTINGS,
            ...initialValues?.settings,
        };
        this.counters = {
            nextCustomerNumber: initialValues?.counters?.nextCustomerNumber ?? MIN_CUSTOMER_NUMBER,
            nextOrderNumber: initialValues?.counters?.nextOrderNumber ?? DEFAULT_ORDER_NUMBERS_START,
            nextCreditorNumber: initialValues?.counters?.nextCreditorNumber ?? DEFAULT_CREDITOR_NUMBERS_START,
            orders: initialValues?.counters?.orders ?? getDefaultOrderCounters(),
            rentals: initialValues?.counters?.rentals ?? getDefaultRentalCounters(),
        };
    }
}

export type CompanyWideSettings = {
    showVatOnReceipts: boolean;
    showPageNumbersOnReceipts: boolean;
    showLogoOnEveryReceiptPage: boolean;
    currency: Currency;
    showNoteQueryForInternalWork: boolean;
    enableMultiCustomerOrders: boolean;
    showColleaguesInTaskDetailsPage: ShowColleaguesSetting;
    /**
     * @deprecated
     */
    showGeolocationTrackingsInTaskDetailsPage: ShowGeolocationTrackingsSetting;
    showOwnGeolocationTrackingsInTaskDetailsPage: ShowOwnGeolocationTrackingsSetting;
    showColleaguesGeolocationTrackingsInTaskDetailsPage: ShowColleagueGeolocationTrackingsSetting;
    specificEmployeesForGeolocationTracking: Employee["id"][];
    enableCheckedOrderStatus: boolean;
    enableMaschinenring: boolean;
    enableProjects: boolean;
    employeeSettings: EmployeeSettings;
    enableTollCollect: boolean;
    enableOrderStatusAutomation: boolean;
};

export type EmployeeSettings = {
    timeTrackingEditRestriction: TimeTrackingRestriction;
    showTotalOvertime: boolean;
    queryOperatingUnitForInternalWork: boolean;
    absenceTypeColors: Record<AbsenceType, string>;
};

export type TimeTrackingRestriction =
    | { type: TimeTrackingRestrictionType.FULL }
    | { type: TimeTrackingRestrictionType.NONE }
    | { type: TimeTrackingRestrictionType.DAYS; days: number };

export enum TimeTrackingRestrictionType {
    /**
     *  No restriction,
     *  employees can edit time trackings.
     */
    NONE = "TimeTrackingRestrictionType_NONE",
    /**
     * Always restricted,
     * employees can not edit time trackings.
     */
    FULL = "TimeTrackingRestrictionType_FULL",
    /**
     * Restricted for days,
     * employees can edit time trackings for the last X days
     */
    DAYS = "TimeTrackingRestrictionType_DAYS",
}

export enum ShowColleaguesSetting {
    NONE = "ShowColleaguesSetting_NONE",
    SAME_CUSTOMER = "ShowColleaguesSetting_SAME_CUSTOMER",
    ALL = "ShowColleaguesSetting_ALL",
}

export enum ShowGeolocationTrackingsSetting {
    NONE = "ShowGeolocationTrackingsSetting_NONE",
    OWN = "ShowGeolocationTrackingsSetting_OWN",
    SPECIFIC_EMPLOYEES_SAME_CUSTOMER = "ShowGeolocationTrackingsSetting_SPECIFIC_EMPLOYEES_SAME_CUSTOMER",
    SAME_CUSTOMER = "ShowGeolocationTrackingsSetting_SAME_CUSTOMER",
}

export enum ShowOwnGeolocationTrackingsSetting {
    NONE = "ShowOwnGeolocationTrackingsSetting_NONE",
    LAST_24_HOURS = "ShowOwnGeolocationTrackingsSetting_LAST_24_HOURS",
    ONLY_ACTIVE = "ShowOwnGeolocationTrackingsSetting_ONLY_ACTIVE",
    ALL = "ShowOwnGeolocationTrackingsSetting_ALL",
}

export enum ShowColleagueGeolocationTrackingsSetting {
    NONE = "ShowColleagueGeolocationTrackingsSetting_NONE",
    SPECIFIC_EMPLOYEES_SAME_CUSTOMER = "ShowColleagueGeolocationTrackingsSetting_SPECIFIC_EMPLOYEES_SAME_CUSTOMER",
    SAME_CUSTOMER = "ShowColleagueGeolocationTrackingsSetting_SAME_CUSTOMER",
}

export const DEFAULT_EMPLOYEE_SETTINGS: EmployeeSettings = {
    timeTrackingEditRestriction: {
        type: TimeTrackingRestrictionType.DAYS,
        days: 7,
    },
    showTotalOvertime: true,
    queryOperatingUnitForInternalWork: false,
    absenceTypeColors: {
        [AbsenceType.VACATION]: "#49A527",
        [AbsenceType.SICK]: "#D93F4B",
        [AbsenceType.PARENTAL_LEAVE]: "#E57B43",
        [AbsenceType.OTHER]: "#D0D0D0",
        [AbsenceType.TRAINING]: "#5887D3",
    },
};

export const DEFAULT_APPCOMPANY_SETTINGS: CompanyWideSettings = {
    showVatOnReceipts: false,
    showPageNumbersOnReceipts: true,
    showLogoOnEveryReceiptPage: true,
    currency: FALLBACK_CURRENCY,
    showNoteQueryForInternalWork: false,
    enableMultiCustomerOrders: false,
    showColleaguesInTaskDetailsPage: ShowColleaguesSetting.SAME_CUSTOMER,
    showGeolocationTrackingsInTaskDetailsPage: ShowGeolocationTrackingsSetting.SAME_CUSTOMER,
    showOwnGeolocationTrackingsInTaskDetailsPage: ShowOwnGeolocationTrackingsSetting.ALL,
    showColleaguesGeolocationTrackingsInTaskDetailsPage: ShowColleagueGeolocationTrackingsSetting.SAME_CUSTOMER,
    specificEmployeesForGeolocationTracking: [],
    enableCheckedOrderStatus: false,
    enableMaschinenring: false,
    enableProjects: false,
    employeeSettings: DEFAULT_EMPLOYEE_SETTINGS,
    enableTollCollect: false,
    enableOrderStatusAutomation: true,
};

export function getShowColleaguesSettingName(setting: ShowColleaguesSetting) {
    switch (setting) {
        case ShowColleaguesSetting.NONE:
            return "Keine";
        case ShowColleaguesSetting.SAME_CUSTOMER:
            return "Gleicher Kunde";
        case ShowColleaguesSetting.ALL:
            return "Alle";
    }
}

export function getShowOwnGeolocationTrackingsSettingName(setting: ShowOwnGeolocationTrackingsSetting) {
    switch (setting) {
        case ShowOwnGeolocationTrackingsSetting.NONE:
            return "Keine";
        case ShowOwnGeolocationTrackingsSetting.ALL:
            return "Alle";
        case ShowOwnGeolocationTrackingsSetting.LAST_24_HOURS:
            return "Letzte 24 Stunden";
        case ShowOwnGeolocationTrackingsSetting.ONLY_ACTIVE:
            return "Aktiver Auftrag";
    }
}

export function getShowColleaguesGeolocationTrackingsSettingName(setting: ShowColleagueGeolocationTrackingsSetting) {
    switch (setting) {
        case ShowColleagueGeolocationTrackingsSetting.NONE:
            return "Keine";
        case ShowColleagueGeolocationTrackingsSetting.SAME_CUSTOMER:
            return "Gleicher Kunde";
        case ShowColleagueGeolocationTrackingsSetting.SPECIFIC_EMPLOYEES_SAME_CUSTOMER:
            return "Spezifische Mitarbeiter beim gleichen Kunden";
    }
}
