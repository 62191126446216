import { CommonInternalWorkType } from "./CommonInternalWorkType";

export enum SpecificAgriculturalContractorInternalWorkType {
    MOUNT = "MOUNT",
    WASH = "WASH",
}

export type AgriculturalContractorInternalWorkType =
    | CommonInternalWorkType
    | SpecificAgriculturalContractorInternalWorkType;
export const AgriculturalContractorInternalWorkType = {
    ...CommonInternalWorkType,
    ...SpecificAgriculturalContractorInternalWorkType,
};
