import { Describe, array, boolean, enums, literal, nullable, number, object, string, union } from "superstruct";
import { v4 } from "uuid";
import {
    AnyDriverQueryTemplate,
    DriverQueryBeforeAfterTemplateStruct,
    DriverQueryResourceOnlyTemplateStruct,
    DriverQueryResourceWithAmountTemplateStruct,
    DriverQuerySingleValueTemplateStruct,
    DriverQueryYesNoTemplateStruct,
    ResourceRestrictionType,
} from "./DriverQuery";
import {
    AnyResourcePriceBlock,
    CalculatedResourcePriceBlock,
    CalculatedResourcePriceBlockResourceType,
    DriverQueryResourcePriceBlock,
} from "./ResourcePriceBlocks";
import {
    AnyServicePriceBlock,
    CalculatedServicePriceBlock,
    DriverQueryServicePriceBlock,
    ProtocolActionServicePriceBlock,
    anyPriceStructureStruct,
    calculatedServicePriceBlockBasisStruct,
    machinePricePlaceholderStruct,
    workTypeRestrictionMapStruct,
} from "./ServicePriceBlocks";
import { ServicePriceUnit } from "./ServicePriceUnit";
import { Machine } from "../Machine";
import { MachineCounterType } from "../MachineCounterTracking";
import { OperatingUnit } from "../OperatingUnit";
import { ResourceUnit } from "../Resource";
import { ResourceTag } from "../ResourceTag";
import { ServiceCategory } from "../ServiceCategory";

export class Service {
    public accountNumber: number | null;
    public active: boolean;
    public archived: boolean;
    public category: ServiceCategory | null;
    public id: string;
    public includeNoteInOrders: boolean;
    public name: string;
    public note: string;
    public operatingUnitIds: Array<OperatingUnit["id"]>;
    public protocolActionConfigs: ProtocolActionConfig[];
    public queryNote: boolean;
    public tags: string[];
    public vatPercentPoints: number | null;
    public weighingConfigs: WeighingConfig[];
    public compatibleMachineIds: Machine["id"][] | false;

    public calculatedServicePriceBlocks: CalculatedServicePriceBlock[];
    public calculatedResourcePriceBlocks: CalculatedResourcePriceBlock[];
    public driverQueryResourcePriceBlocks: DriverQueryResourcePriceBlock[];
    public driverQueryServicePriceBlocks: DriverQueryServicePriceBlock[];
    public protocolActionServicePriceBlocks: ProtocolActionServicePriceBlock[];

    public driverQueryTemplates: AnyDriverQueryTemplate[];

    public static RentalService = new Service({ id: "RENTAL", name: "Vermietung" });

    constructor(initialValues?: Partial<Service>) {
        this.accountNumber = initialValues?.accountNumber ?? null;
        this.active = initialValues?.active ?? true;
        this.archived = initialValues?.archived ?? false;
        this.category = initialValues?.category ?? null;
        this.id = initialValues?.id ?? "";
        this.includeNoteInOrders = initialValues?.includeNoteInOrders ?? false;
        this.name = initialValues?.name ?? "";
        this.note = initialValues?.note ?? "";
        this.operatingUnitIds = initialValues?.operatingUnitIds ?? [];
        this.protocolActionConfigs = initialValues?.protocolActionConfigs ?? [];
        this.queryNote = initialValues?.queryNote ?? false;
        this.tags = initialValues?.tags ?? [];
        this.vatPercentPoints = initialValues?.vatPercentPoints ?? null;
        this.weighingConfigs = initialValues?.weighingConfigs ?? [];
        this.compatibleMachineIds = initialValues?.compatibleMachineIds ?? false;

        this.calculatedResourcePriceBlocks = initialValues?.calculatedResourcePriceBlocks ?? [];
        this.calculatedServicePriceBlocks = initialValues?.calculatedServicePriceBlocks ?? [];
        this.driverQueryResourcePriceBlocks = initialValues?.driverQueryResourcePriceBlocks ?? [];
        this.driverQueryServicePriceBlocks = initialValues?.driverQueryServicePriceBlocks ?? [];
        this.protocolActionServicePriceBlocks = initialValues?.protocolActionServicePriceBlocks ?? [];

        this.driverQueryTemplates = initialValues?.driverQueryTemplates ?? [];
    }
}

export type AnyPriceBlock = AnyServicePriceBlock | AnyResourcePriceBlock;

export function isServicePriceUnit(unit: any): unit is ServicePriceUnit {
    if (!unit) {
        return false;
    }
    return Object.values(ServicePriceUnit).includes(unit);
}

export type ProtocolActionConfig = {
    id: string;
    name: string;
    queries: {
        mapStructure: boolean;
        note: boolean;
        volume: ProtocolVolumeSettingType;
        resource: ProtocolResourceQuerySettings | null;
    };
    customUnit: string | null;
};

export enum ProtocolVolumeSettingType {
    AUTOMATIC = "AUTOMATIC",
    MANUAL_WITHOUT_PRESET = "MANUAL_WITHOUT_PRESET",
    MANUAL_WITH_PRESET = "MANUAL_WITH_PRESET",
}

export type ProtocolResourceQuerySettings = {
    billResource: boolean;
    restriction: ProtocolResourceRestriction;
};

export type ProtocolResourceRestrictionNone = {
    type: ResourceRestrictionType.NONE;
};

export type ProtocolResourceRestrictionCategory = {
    type: ResourceRestrictionType.CATEGORY;
    tags: ResourceTag["id"][];
};

export type ProtocolResourceRestrictionResource = {
    type: ResourceRestrictionType.RESOURCE;
    resourceId: string;
    resourceVariantId: string | null;
};

export type ProtocolResourceRestriction =
    | ProtocolResourceRestrictionNone
    | ProtocolResourceRestrictionCategory
    | ProtocolResourceRestrictionResource;

export type WeighingConfig = {
    id: string;
    name: string;
    unit: ResourceUnit.KILOGRAM | ResourceUnit.TON;
};

export const DEFAULT_PROTOCOL_ACTION_CONFIG: ProtocolActionConfig = {
    id: v4(),
    name: "",
    queries: {
        note: false,
        volume: ProtocolVolumeSettingType.AUTOMATIC,
        mapStructure: false,
        resource: null,
    },
    customUnit: null,
};

export const DEFAULT_WEIGHING_CONFIG: WeighingConfig = {
    id: v4(),
    name: "",
    unit: ResourceUnit.TON,
};

export const ServiceStruct: Describe<Service> = object({
    accountNumber: nullable(number()),
    active: boolean(),
    archived: boolean(),
    category: nullable(enums(Object.values(ServiceCategory))),
    id: string(),
    includeNoteInOrders: boolean(),
    name: string(),
    note: string(),
    operatingUnitIds: array(string()),
    queryNote: boolean(),
    compatibleMachineIds: union([array(string()), literal(false)]),
    protocolActionConfigs: array(
        object({
            id: string(),
            customUnit: nullable(string()),
            name: string(),
            queries: object({
                mapStructure: boolean(),
                note: boolean(),
                volume: enums(Object.values(ProtocolVolumeSettingType)),
                resource: nullable(
                    object({
                        billResource: boolean(),
                        restriction: union([
                            object({
                                type: literal(ResourceRestrictionType.CATEGORY),
                                tags: array(string()),
                            }),
                            object({
                                type: literal(ResourceRestrictionType.RESOURCE),
                                resourceId: string(),
                                resourceVariantId: nullable(string()),
                            }),
                            object({
                                type: literal(ResourceRestrictionType.NONE),
                            }),
                        ]),
                    })
                ),
            }),
        })
    ),
    tags: array(string()),
    vatPercentPoints: nullable(number()),
    weighingConfigs: array(
        object({
            id: string(),
            name: string(),
            unit: enums([ResourceUnit.TON, ResourceUnit.KILOGRAM]),
        })
    ),

    driverQueryTemplates: array(
        union([
            DriverQuerySingleValueTemplateStruct,
            DriverQueryYesNoTemplateStruct,
            DriverQueryBeforeAfterTemplateStruct,
            DriverQueryResourceWithAmountTemplateStruct,
            DriverQueryResourceOnlyTemplateStruct,
        ])
    ),
    calculatedServicePriceBlocks: array(
        object({
            id: string(),
            receiptText: string(),
            price: union([anyPriceStructureStruct, machinePricePlaceholderStruct]),
            workTypeRestriction: nullable(workTypeRestrictionMapStruct),
            basis: calculatedServicePriceBlockBasisStruct,
            maschinenring: object({
                vskz: nullable(string()),
                fuelTarget: boolean(),
            }),
        })
    ),
    driverQueryServicePriceBlocks: array(
        object({
            id: string(),
            receiptText: string(),
            price: anyPriceStructureStruct,
            workTypeRestriction: nullable(workTypeRestrictionMapStruct),
            driverQueryTemplate: string(),
            maschinenring: object({
                vskz: nullable(string()),
                fuelTarget: boolean(),
            }),
        })
    ),
    calculatedResourcePriceBlocks: array(
        object({
            id: string(),
            resource: union([
                object({
                    type: literal(CalculatedResourcePriceBlockResourceType.FIXED),
                    resourceId: string(),
                    resourceVariantId: string(),
                }),
                object({
                    type: literal(CalculatedResourcePriceBlockResourceType.DRIVER_QUERY),
                    driverQueryTemplateId: string(),
                }),
            ]),
            amount: number(),
            basis: union([
                enums(Object.values(ServicePriceUnit)),
                enums(Object.values(MachineCounterType)),
                object({
                    driverQueryTemplate: string(),
                }),
                object({
                    protocolActionConfigId: string(),
                }),
            ]),
            workTypeRestriction: nullable(workTypeRestrictionMapStruct),
        })
    ),
    driverQueryResourcePriceBlocks: array(
        object({
            id: string(),
            driverQueryTemplate: string(),
        })
    ),

    protocolActionServicePriceBlocks: array(
        object({
            id: string(),
            receiptText: string(),
            price: anyPriceStructureStruct,
            workTypeRestriction: nullable(workTypeRestrictionMapStruct),
            protocolActionConfigId: string(),
            maschinenring: object({
                vskz: nullable(string()),
                fuelTarget: boolean(),
            }),
        })
    ),
});

export const MASCHINENRING_TAG = "MR";
