import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { getSingularPluralTextFormatter } from "@farmact/utils/src/formatters";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import dayjs from "dayjs";
import { ComponentPropsWithoutRef, MouseEvent } from "react";
import { useTableGroupContext } from "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/useTableGroupContext";
import { Pulse } from "@/components/snippets/Pulse/Pulse";
import { calculateTimeTrackingAmount } from "@/util/calculationUtils";
import { computed } from "@/util/functions";
import { filterTimeTrackingsOnlyBreaks, filterTimeTrackingsWithoutBreak } from "@/util/timeTrackingUtils";
import { getFormattedEndOfDay, getFormattedStartOfDay } from "./tableGroupHeaderUtils";
import "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/TableGroupTopLevel/tableGroupTopLevel.scss";

type TableGroupHeaderProps = ComponentPropsWithoutRef<"div">;

export const TableGroupHeader = (props: TableGroupHeaderProps) => {
    const { open, setOpen, group } = useTableGroupContext();

    const startOfDay = getFormattedStartOfDay(group);
    const endOfDay = getFormattedEndOfDay(group);

    const breakTimeTrackings = filterTimeTrackingsOnlyBreaks(group.timeTrackings);
    const workTimeTrackings = filterTimeTrackingsWithoutBreak(group.timeTrackings);

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        setOpen(!open);
        props.onClick?.(event);
    };

    const isCurrentActivityInGroup = computed(() => {
        if (!group.currentActivity) {
            return false;
        }
        const startOfGroup = dayjs(group.day).startOf("date");
        const endOfGroup = dayjs(group.day).endOf("date");
        if (startOfGroup.isAfter(dayjs().endOf("date"))) {
            return false;
        }
        if (dayjs(group.currentActivity.startDateTime).isAfter(endOfGroup)) {
            return false;
        }
        return true;
    });

    return (
        <div
            {...props}
            className={clsx("table-group-top-level", props.className, {
                "table-group-top-level--open": open,
                "table-group-top-level--closed": !open,
            })}
            onClick={handleClick}>
            <div className="table-group-top-level__cell table-group-top-level__date">
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className={clsx("group-icon", {
                        "group-icon--open": open,
                        "group-icon--closed": !open,
                    })}
                />
                <span className="group-date__day">{formatDay(group.day)}</span>
                <span className="group-date__date">{formatDate(group.day)}</span>
            </div>
            <div className="table-group-top-level__cell table-group-top-level__work-time">
                {startOfDay}
                <span>-</span>
                {endOfDay}
            </div>
            <div className="table-group-top-level__cell table-group-top-level__time">
                {formatTotalWorkTime(workTimeTrackings)}
            </div>
            <div className="table-group-top-level__cell table-group-top-level__break">
                {formatBreakTime(breakTimeTrackings)}
            </div>
            <div className="table-group-top-level__cell table-group-top-level__total">
                {formatTotalWorkTime(workTimeTrackings)}
            </div>
            <div className="table-group-top-level__cell table-group-top-level__activities">
                {formatActivitiesCount(workTimeTrackings.length + (isCurrentActivityInGroup ? 1 : 0))}
            </div>
            <div className="table-group-top-level__cell table-group-top-level__notes">
                {formatNotesCount(group.timeTrackings.filter(tt => !!tt.note).length)}
            </div>
            <div className="table-group-top-level__cell">{group.currentActivity && <Pulse color="primary" />}</div>
        </div>
    );
};

function formatDay(day: Date): string {
    return dayjs(day).locale("de").format("dd.");
}

function formatDate(day: Date): string {
    return dayjs(day).format("DD.MM");
}

function formatBreakTime(timeTrackings: TimeTracking[]): string {
    const totalBreakTimeMinutes = calculateTimeTrackingAmount(timeTrackings, "minutes");

    const breakTimeHours = Math.floor(totalBreakTimeMinutes / 60);
    const breakTimeMinutes = Math.round(totalBreakTimeMinutes % 60);

    if (breakTimeHours > 0 && breakTimeMinutes > 0) {
        return `${breakTimeHours} h ${breakTimeMinutes} min`;
    } else if (breakTimeHours > 0) {
        return `${breakTimeHours} h`;
    }
    return `${breakTimeMinutes} min`;
}

function formatTotalWorkTime(timeTrackings: TimeTracking[]): string {
    const totalTime = calculateTimeTrackingAmount(timeTrackings);

    const roundedMinutes = Math.round(totalTime);
    const hours = Math.floor(roundedMinutes / 60);
    const minutes = roundedMinutes % 60;

    return `${hours}:${minutes.toString().padStart(2, "0")} h`;
}

const activitySPFormatter = getSingularPluralTextFormatter("Aktivität", "Aktivitäten");

function formatActivitiesCount(activities: number): string {
    return `${activities} ${activitySPFormatter(activities)}`;
}

const noteSPFormatter = getSingularPluralTextFormatter("Notiz", "Notizen");

function formatNotesCount(numberOfNotes: number): string {
    return `${numberOfNotes} ${noteSPFormatter(numberOfNotes)}`;
}
