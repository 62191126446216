import { IonButton, isPlatform } from "@ionic/react";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import clsx from "clsx";
import { ReactNode } from "react";
import { AlertAction } from "./FaAlertContext";
import "./faAlert.scss";

export type FaAlertProps = {
    isOpen: boolean;
    onDismiss: () => void;
    /** @default true */
    backdropDismiss?: boolean;

    title?: string;
    message: ReactNode;

    actions?: AlertAction[];

    className?: string;
};

// TODO: does this need to be mocked like the modal?
// TODO: look through the usages and avoid closing modals when showing alerts

export function FaAlert(props: FaAlertProps) {
    const handleOpenChange = (open: boolean) => {
        if (!open) {
            props.onDismiss?.();
        }
    };

    return (
        <AlertDialog.Root open={props.isOpen} onOpenChange={handleOpenChange}>
            <AlertDialog.Portal>
                <AlertDialog.Overlay
                    className="fa-alert__overlay"
                    onClick={() => {
                        if (props.backdropDismiss !== false) {
                            props.onDismiss();
                        }
                    }}
                />
                <AlertDialog.Content
                    data-fa-popover-target
                    className={clsx(
                        "fa-alert__content",
                        isPlatform("mobile") && "fa-modal__content--mobile",
                        props.className
                    )}>
                    {props.title && <AlertDialog.Title className="fa-alert__title">{props.title}</AlertDialog.Title>}
                    <AlertDialog.Description className="fa-alert__message">{props.message}</AlertDialog.Description>
                    <div className="fa-alert__actions">
                        {props.actions && props.actions.length > 0 ? (
                            props.actions.map(action => (
                                <IonButton
                                    onClick={() => {
                                        action.handler?.();
                                        props.onDismiss();
                                    }}
                                    color={action.color}
                                    key={action.text}>
                                    {action.text}
                                </IonButton>
                            ))
                        ) : (
                            <AlertDialog.Action asChild>
                                <IonButton>Ok</IonButton>
                            </AlertDialog.Action>
                        )}
                    </div>
                </AlertDialog.Content>
            </AlertDialog.Portal>
        </AlertDialog.Root>
    );
}
