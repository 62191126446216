import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { InternalWorkType, getInternalWorkTypeName } from "@farmact/model/src/model/workTypes/InternalWorkType";
import dayjs from "dayjs";
import { normalizeTimeTracking } from "@/util/timeTrackingUtils";
import { TimeTrackingWithOrder } from "./useTimeTrackingValidation";

export function getErrorMessages(
    timeTrackingsWithOrders: TimeTrackingWithOrder[],
    timeTrackingToValidate: TimeTracking | null
) {
    const errorMessages: string[] = [];
    if (!timeTrackingToValidate) {
        return errorMessages;
    }
    for (const trackingWithOrder of timeTrackingsWithOrders) {
        const tracking = trackingWithOrder.timeTracking;
        if (timeTrackingsDoNotOverlap(tracking, timeTrackingToValidate)) {
            continue;
        }
        const time = `${dayjs(tracking.startDateTime).format("DD.MM.YYYY")}, ${dayjs(tracking.startDateTime).format(
            "HH:mm"
        )} - ${dayjs(tracking.endDateTime).format("HH:mm")}`;
        if (!tracking.order) {
            errorMessages.push(
                `Interne Arbeit (${getInternalWorkTypeName(
                    tracking.internalWorkType ?? InternalWorkType.USE
                )}): ${time}`
            );
            continue;
        }
        const order = trackingWithOrder.order;
        errorMessages.push(`Auftrag ${order?.orderNumber ?? "gelöschter Auftrag"}: ${time}`);
    }
    return errorMessages;
}

export function timeTrackingsDoNotOverlap(timeTracking1: TimeTracking, timeTracking2: TimeTracking) {
    const normalizedTimeTracking1 = normalizeTimeTracking(timeTracking1);
    const normalizedTimeTracking2 = normalizeTimeTracking(timeTracking2);
    return (
        (normalizedTimeTracking1.startDateTime <= normalizedTimeTracking2.startDateTime &&
            normalizedTimeTracking1.endDateTime <= normalizedTimeTracking2.startDateTime) ||
        (normalizedTimeTracking1.startDateTime >= normalizedTimeTracking2.endDateTime &&
            normalizedTimeTracking1.endDateTime >= normalizedTimeTracking2.endDateTime)
    );
}
